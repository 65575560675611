
import OImage from "@/components/base/image/OImage"
import Swiper from "swiper"
import "swiper/swiper.scss"

export default {
    name: "Gallery",
    components: {
        OImage,
    },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        row1() {
            return this.blok.media.slice(0, this.blok.media.length / 2)
        },
        row2() {
            return this.blok.media.slice(
                this.blok.media.length / 2 + 1,
                this.blok.media.length
            )
        },
    },
    mounted() {
        new Swiper(this.$refs.row, {
            slidesPerView: "auto",
            spaceBetween: 0,
        })
        new Swiper(this.$refs.row2, {
            slidesPerView: "auto",
            spaceBetween: 0,
        })
    },
    methods: {
        onClick(index) {
            const media = this.blok.media.map((f, i) => ({
                id: i,
                local: f.filename,
            }))
            this.$fullscreenMedia.open(media, { initialId: index })
        },
    },
}
